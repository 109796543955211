<template>
    <div class="card-tail">
        <div>
            <el-form>
                <div class="p-5">
                    <header class="text-header-blue text-xl font-semibold mb-8">Noter</header>
                    <div v-if="notesData" v-loading="$waiting.is('loading')">
                        <div v-for="(card, index) in notesData.cards" :key="index">
                            <NotesCards
                                :annual-report-id="annualReportId"
                                :card-data="card"
                                :last-index="notesData.cards.length"
                                :notes-data="notesData"
                                :index="index"
                                @move-up="moveUp"
                                @move-down="moveDown"
                                @saveData="saveData()"
                                @close="closeCard"
                                @move-unselected-to-bottom="moveUnselectedToBottom"
                            />
                        </div>
                    </div>
                </div>

                <NotesDraft
                    ref="inspectNotesModal"
                    :client-info="clientInfo"
                    :visible="draftVisible"
                    @close="draftVisible = false"
                    :data="accountingPrinciplesData"
                    :notes-data="notesData"
                    :is-notes="true"
                />

                <div class="p-5 flex justify-end w-full">
                    <el-button size="medium" type="primary" @click="goToPageBefore">Tillbaka</el-button>
                    <el-button size="medium" type="warning">Utkast Årsredovisning</el-button>
                    <el-button size="medium" class="button is-info" @click="showDraft">Granska</el-button>
                    <el-button size="medium" type="primary" @click="goToNextPage">Nästa</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import Api from "../annualReport.api";
export default {
    props: {
        clientInfo: {
            type: Object,
        },
    },
    components: {
        NotesCards: () => import("./components/NotesCards.vue"),
        NotesDraft: () => import("../components/NotesDraft.vue"),
    },
    data() {
        return {
            annualReportId: undefined,
            draftVisible: false,
            notesData: {},
            oldInputText: "",
            addNewTextCard: false,
            accountingPrinciplesData: {},
        };
    },
    async created() {
        this.$waiting.start("loading");
        this.annualReportId = this.$route.params.annualReportId;
        this.notesData = await Api.getNotesDetails(this.annualReportId);
        this.$waiting.end("loading");
        this.accountingPrinciplesData = await Api.getAccountingPrincipleDetails(this.annualReportId);
    },
    methods: {
        async goToNextPage() {
            await this.$router.push(`/annualReport/signatures/${this.annualReportId}`);
        },
        async goToPageBefore() {
            await this.$router.push(`/annualReport/accountingPrinciples/${this.annualReportId}`);
        },
        showDraft() {
            this.draftVisible = true;
        },
        async saveData() {
            await Api.updateNotesDetails(this.annualReportId, this.notesData);
        },
        async closeCard() {
            //value = {}
            // this.$waiting.start(value.load);
            this.notesData = await Api.getNotesDetails(this.annualReportId);
            // this.$waiting.end(value.load);
        },
        toggleNewTextCard() {
            this.addNewTextCard = this.addNewTextCard == false ? true : false;
        },

        async moveUp(value) {
            if (value.index > 0) {
                const cardToMoveUp = this.notesData.cards[value.index];
                this.notesData.cards.splice(value.index, 1);
                this.notesData.cards.splice(value.index - 1, 0, cardToMoveUp);

                await this.switchNotesNumbersMovingUp(value, cardToMoveUp);
            } else if (value.index === 0) {
                this.$notify.error("Detta är första kortet");
            }
        },
        async switchNotesNumbersMovingUp(value, cardToMoveUp) {
            const cardMovedDown = Object.values(this.notesData.cards[value.index]).find(row => row != null);
            const cardMoveUp = Object.values(cardToMoveUp).find(row => row != null);
            cardMoveUp.notesNumber = value.index + 1;
            cardMovedDown.notesNumber = value.index + 2;
            await this.updateNotesNumbers(cardMoveUp, cardMovedDown);
        },
        async moveDown(value) {
            const lastIndex = this.notesData.cards.length - 1;
            if (value.index < lastIndex) {
                const cardToMoveDown = this.notesData.cards[value.index];
                this.notesData.cards.splice(value.index, 1);
                this.notesData.cards.splice(value.index + 1, 0, cardToMoveDown);

                await this.switchNotesNumbersMovingDown(value, cardToMoveDown);
            } else if (value.index === lastIndex) {
                this.$notify.error("Detta är sista kortet");
            }
        },
        async switchNotesNumbersMovingDown(value, cardToMoveDown) {
            const cardMovedUp = Object.values(this.notesData.cards[value.index]).find(row => row != null);
            const cardMovedDown = Object.values(cardToMoveDown).find(row => row != null);
            cardMovedDown.notesNumber = value.index + 3;
            cardMovedUp.notesNumber = value.index + 2;

            await this.updateNotesNumbers(cardMovedUp, cardMovedDown);
        },
        async updateNotesNumbers(cardMoveUp, cardMovedDown) {
            const switchData = {
                selectedBalanceNoteNumbers: this.notesData.selectedBalanceNoteNumbers.flat(),
                selectedResultNoteNumbers: this.notesData.selectedResultNoteNumbers.flat(),
                cardMovedUp: { title: cardMoveUp.headTitle, newNotesNumber: cardMoveUp.notesNumber, add: cardMoveUp.add },
                cardMovedDown: { title: cardMovedDown.headTitle, newNotesNumber: cardMovedDown.notesNumber, add: cardMovedDown.add },
            };
            const updatedNotesNumbers = await Api.updateNotesNumbers(this.annualReportId, switchData);
            this.notesData.balanceNotesNumbers = updatedNotesNumbers.balanceNotesNumbers;
            this.notesData.incomeStatementNotesNumbers = updatedNotesNumbers.incomeStatementNotesNumbers;
        },

        async moveUnselectedToBottom(eventData) {
            const cards = await this.notesData.cards;
            const lastIndex = cards.length - 1;
            if (eventData.index < lastIndex) {
                const cardToMoveDown = cards[eventData.index];
                if (cardToMoveDown) {
                    cards.splice(eventData.index, 1);
                    cards.splice(lastIndex, 0, cardToMoveDown);
                }
                this.updateNoteNumbersBelow(eventData, cards);
            }
        },
        updateNoteNumbersBelow(eventData, cards) {
            for (let i = eventData.index; i < cards.length; i++) {
                const cardMovedDown = Object.values(cards[i]).find(row => row != null);
                cardMovedDown.notesNumber = i + 2;
            }
        },
    },
};
</script>

<style>
.custom-textarea .el-textarea__inner:disabled {
    color: #444a55;
    border-color: #aaaaaa;
    background-color: #eeeeee;
    font-size: 14px;
}
.custom-textarea {
    font-size: 14px;
}
.round-button,
.round-button:active,
.round-button:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #415770;
    font-size: x-large;
}
.round-button:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #6e85a0;
    font-size: x-large;
}
.save-button {
    background-color: #48c78e;
    border-color: #48c78e;
    color: #ffffff;
}
.save-button:hover {
    background-color: #5bd6a3;
    color: #ffffff;
}
.tooltip-content {
    width: 500px;
    padding: 5px;
    border: none;
}
.management-report-card-box {
    margin-top: 40px;
    max-width: fit-content;
    min-width: 800px;
    overflow: auto;
}
</style>
